import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

class TabsTwo extends Component {
    render() {
        let
            tab1 = "Tecnologias";
        const { tabStyle } = this.props
        return (
            <div>
                {/* Start Tabs Area */}
                <div className="tabs-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <Tabs>
                                    <TabList className={`${tabStyle}`}>
                                        <Tab>{tab1}</Tab>
                                    </TabList>
                                    <TabPanel>
                                        <div className="single-tab-content">
                                            <ul>
                                                <li>
                                                    <span style={{ fontWeight: 'bold' }}>Desarrollo Frontend:</span> <br />
                                                    {`HTML, CSS, JS, React`}
                                                </li>
                                                <li>
                                                    <span style={{ fontWeight: 'bold' }}>Desarrollo Backend:</span> <br />
                                                    {`.NET, C#, NodeJS, SQL Server, MySQL, MongoDB`}
                                                </li>
                                                <li>
                                                    <span style={{ fontWeight: 'bold' }}>Arquitectura en la nube:</span> <br />
                                                    {`Azure Cloud Services`}
                                                </li>
                                            </ul>
                                        </div>
                                    </TabPanel>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Tabs Area */}
            </div>
        )
    }
}



export default TabsTwo;