import React from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'

const Testimonial = () => {

    const testimonios = [
        {
            testimonio: 'La dinámica y paciencia que tienes para explicar. excelente 💯',
            nombre: 'Rosmy Quijada',
            empresa: 'Personal',
            cargo: 'Comerciante',
        },
        {
            testimonio: 'Me encanta la disposición de Luis de siempre enseñar y volver a explicar lo que necesite el participante, siempre atento a que el aprendizaje esperado se logre',
            nombre: 'Marian Villarroel',
            empresa: 'La Cocina de Ita',
            cargo: 'CEO',
        },
        {
            testimonio: 'Me gusta la dinámica, el hecho de que sea un curso con mucha practica y el esfuerzo que se dedica a que los alumnos entendamos el tema. Los eventos de los sábados son muy útiles y entusiasman más con el aprendizaje.',
            nombre: 'Tulio De Leon',
            empresa: 'Martech',
            cargo: 'Ingeniero de Soporte',
        },
        {
            testimonio: 'Excelente curso, contenido de calidad, orientacion personalizada, ambiente grato y muy buen profesor. Felicidades.',
            nombre: 'Andres Hernandez',
            empresa: 'Sefutec',
            cargo: 'Ingeniero de Proyectos',
        },
        {
            testimonio: 'Este curso esta diseñado por profesionales de la informatica enfocado en personas que quieren ampliar sus conocimientos, que quieren cambiar de rubro, que quieren tener una introduccion a la informatica y que quieren hacer carrera. El enfoque y metodo es totalmente didactico y enfocado a la practica, el capacitador te hace sentir como si estuvieras trabajando ya en algun proyecto, lo que le da un toque real y eleva la experiencia del alumno a lo que realmente busca. Recomendado 100%',
            nombre: 'Marcos Garcia',
            empresa: 'Grupo Nuestros Parques',
            cargo: 'Coordinador Comercial',
        },
        {
            testimonio: 'Me gusta la disponibilidad del profesor y las actividades prácticas.',
            nombre: 'Vianney Gutierrez',
            empresa: 'Sachiel Fancy Shop',
            cargo: 'CEO',
        },
    ]

    return (
        <div className="row">
            <div className="col-lg-12">
                <div className="section-title text-center service-style--3 mb--30 mb_sm--0">
                    <h2 className="title">Testimonios</h2>
                    <p>La opinión de los estudiantes con respecto a la academia...</p>
                </div>
            </div>
            <div className="col-lg-12">
                <Tabs>

                    {testimonios.map(t =>
                        <TabPanel>
                            <div className="rn-testimonial-content text-center">
                                <div className="inner">
                                    <p>{t.testimonio}</p>
                                </div>
                                <div className="author-info">
                                    <h6>
                                        <span>{t.nombre} </span>
                                        - {t.cargo}, {t.empresa}
                                    </h6>
                                </div>
                            </div>
                        </TabPanel>
                    )}


                    <TabList className="testimonial-thumb-wrapper">
                        {testimonios.map(() =>
                            <Tab>
                                <div className="testimonial-thumbnai">
                                    <div className="thumb">
                                        <img src={`/assets/images/user.png`} alt="Testimonial Images" />
                                    </div>
                                </div>
                            </Tab>
                        )}
                    </TabList>
                </Tabs>
            </div>
        </div>
    )

}
export default Testimonial;