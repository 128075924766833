import React, { Component } from "react";

class ContactThree extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rnName: '',
            rnEmail: '',
            rnSubject: '',
            rnMessage: '',
        };
    }
    render() {
        return (
            <div className="contact-form--1">
                <div className="container">
                    <div className="row row--35 align-items-start">
                        <div className="col-lg-12 order-2 order-lg-1">
                            <div className="section-title text-left mb--50">
                                <h2 className="title text-center">{this.props.contactTitle}</h2>
                                <p className="description text-center">
                                    Si tienes alguna pregunta o sugerencia, no dudes en escribirnos: <br />
                                    {/* Telf: <a href="tel:+56990564966">{`(56) 9 9056 4966`}</a><br /> */}
                                    Email: <a href="mailto:info@leomontdev.com">info@leomontdev.com</a>
                                </p>
                            </div>
                            {/* <div className="form-wrapper">
                                <form>
                                    <label htmlFor="item01">
                                        <input
                                            type="text"
                                            name="name"
                                            id="item01"
                                            value={this.state.rnName}
                                            onChange={(e) => { this.setState({ rnName: e.target.value }); }}
                                            placeholder="Nombre *"
                                        />
                                    </label>

                                    <label htmlFor="item02">
                                        <input
                                            type="text"
                                            name="email"
                                            id="item02"
                                            value={this.state.rnEmail}
                                            onChange={(e) => { this.setState({ rnEmail: e.target.value }); }}
                                            placeholder="Email *"
                                        />
                                    </label>

                                    <label htmlFor="item03">
                                        <input
                                            type="text"
                                            name="subject"
                                            id="item03"
                                            value={this.state.rnSubject}
                                            onChange={(e) => { this.setState({ rnSubject: e.target.value }); }}
                                            placeholder="Asunto"
                                        />
                                    </label>
                                    <label htmlFor="item04">
                                        <textarea
                                            type="text"
                                            id="item04"
                                            name="message"
                                            value={this.state.rnMessage}
                                            onChange={(e) => { this.setState({ rnMessage: e.target.value }); }}
                                            placeholder="Mensaje*"
                                        />
                                    </label>
                                    <button className="rn-button-style--2 btn-solid" type="submit" value="submit" name="submit" id="mc-embedded-subscribe">Enviar Mensaje</button>
                                </form>
                            </div> */}
                        </div>
                    </div>
                    <div className="row row--35 align-items-start">
                        <div className="col-lg-12 text-center order-1 order-lg-2">
                            <div className="thumbnail mb_md--30 mb_sm--30">
                                <a href="https://discord.gg/TXKT6MSuyj" target="_blank" rel="noopener noreferrer">
                                    <img src={`${this.props.contactImages}`} alt="discord" style={{ maxWidth: '400px' }} />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default ContactThree;