import React ,{ Component }from "react";
import { FiLayers , FiBookOpen, FiHelpCircle, FiThumbsUp, FiStar, FiTarget } from "react-icons/fi";

const ServiceList = [
    {
        icon: <FiTarget />,
        title: 'Proyectos Personalizados',
        description: 'Realizamos los proyectos que tengas en mente, desde cero y como tu lo quieras.'
    },
    {
        icon: <FiLayers />,
        title: 'Productos Propios',
        description: 'Realizamos proyectos propios comercializables a fin de solucionar alguna necesidad en especifico.'
    },
    {
        icon: <FiStar />,
        title: 'Ideas de Proyectos',
        description: 'Tienes una idea en mente con un futuro brillante, veamosla y trabajemos en conjunto.'
    },
    { 
        icon: <FiThumbsUp />,
        title: 'Consultoria',
        description: 'Si quieres hacer tu propio proyecto pero no sabes como empezar o continuar, tambien te ayudamos a organizarlo.'
    },
    {
        icon: <FiHelpCircle />,
        title: 'Ayuda',
        description: 'Aprovecha nuestros canales de ayuda, quizas tengamos la respuesta que buscas.'
    },
    { 
        icon: <FiBookOpen />,
        title: 'Academia',
        description: 'Si estas interesado en aprender sobre algun tema en programacion, te dejamos un listado de los cursos disponibles en la seccion Academia'
    }
]


class ServiceThree extends Component{
    render(){
        const {column } = this.props;
        const ServiceContent = ServiceList.slice(0 , this.props.item);
        
        return(
            <React.Fragment>
                <div className="row">
                    {ServiceContent.map( (val , i) => (
                        <div className={`${column}`} key={i}>
                            <a>
                                <div className="service service__style--2">
                                    <div className="icon">
                                        {val.icon}
                                    </div>
                                    <div className="content">
                                        <h3 className="title">{val.title}</h3>
                                        <p>{val.description}</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceThree;
